import React ,{ createContext } from "react";

 const DragContext=createContext();


 
 
 export default DragContext;
 


 

